@import "../.././../common.scss";

.home_franchise {
  padding: 3.75em 0 3.75em;
  .franchise_title {
    text-align: center;
  }
  .franchise_row {
    padding: 3.75em 0 0;
    align-items: stretch;
    .franchise_box {
      height: 100%;
      padding: 3em 2em 6.875em;
      margin: 0 0.75em;
      background: $color-F4F4F4;
      transition: all 0.35s ease-in;
      cursor: pointer;
      &:hover {
        box-shadow: 0px 6px 9px $color-00000029;
        transition: all 0.35s ease-in;
      }
      .fran_title {
        @include textStyles(2em, normal, 0);
        text-transform: uppercase;
        color: $color-A32D3D;
        font-weight: 500;
      }
      .fran_list {
        .list_item {
          display: flex;
          justify-content: space-between;
          padding: 2.222em 0 0;
          @include textStyles(1.125em, normal, 0);
          .fran_heading {
            width: 100%;
            max-width: 60%;
            font-weight: 500;
          }
          .fran_value {
            width: 100%;
            max-width: 40%;
            font-weight: 900;
            text-align: right;
          }
        }
      }
    }
  }
}

@media (max-width: 1536px) {
  .home_franchise {
    .franchise_row {
      .franchise_box {
        .fran_list {
          .list_item {
            font-size: 1.455em;
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .home_franchise {
    padding: 3em 0 0.6em;
    .franchise_row {
      .franchise_box {
        padding: 3.2em 2em;
        .fran_list {
          .list_item {
            @include textStyles(1.4em, 1.857em, 0);
            padding: 1.429em 0 0;
            .fran_heading {
              max-width: fit-content;
              padding: 0 1em 0 0;
            }
            .fran_value {
              max-width: fit-content;
            }
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .home_franchise {
    .franchise_title {
      text-align: left;
    }
    .franchise_row {
      padding: 2.4em 1.6em 0;
      .mobile_box {
        margin: 0 0 2.4em;
        min-height: 21.5em;
      }
    }
  }
}

@media (max-width: 600px) {
  .home_franchise {
    .franchise_row {
      .franchise_box {
        box-shadow: 0px 6px 9px $color-00000029;
      }
    }
  }
}
