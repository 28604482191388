@import "../../common.scss";

.footer_sec1 {
  position: relative;
  background-color: $color-393939;
  color: $color-ffffff;
  padding: 3.75em 0;
  .footer_main {
    padding: 0 0 3.313em;

    .logo_wrapper {
      .footer_logo {
        width: 100%;
        max-width: 16.875em;
        margin-bottom: 1.375em;
      }
    }
    .links_wrapper {
      .link_heading {
        @include textStyles(1.5em, 1.25em, 0);
        font-family: $alike;
        margin-bottom: 1em;
      }
      .description {
        @include textStyles(1.25em, 1.25em, 0);
        color: $color-ffffff;
        margin-bottom: 1em;
        max-width: 17.6em;
      }
      .social_wrapper {
        display: flex;
        align-items: flex-end;
        .social_box {
          margin: 0 1.563em;
          &:first-child {
            margin-left: 0;
          }
          &:last-child {
            margin-right: 0;
          }
          .social_icon {
            width: 100%;
            max-width: 1.688em;
            height: 100%;
            object-fit: contain;
          }
        }
      }
    }
  }
  .copyright_flex {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba($color: $color-ffffff, $alpha: 1);
    padding: 3em 0 0;
    .copyright {
      display: block;
      @include textStyles(1em, 1.25em, 0);
      color: $color-ffffff;
    }
    .togglehead_link {
      color: $color-ffffff;
      font-weight: 300;
      text-decoration: none;
      &:hover {
        padding-bottom: 0.05em;
      }
    }
  }
}

@media (max-width: 600px) {
  .footer_sec1 {
    padding: 4em 0 2em;
    .footer_main {
      padding: 0;
      .logo_wrapper {
        padding: 0 0 4.4em;
        .footer_logo {
          max-width: 15em;
        }
      }
      .links_wrapper {
        margin-bottom: 4em;
        .description {
          font-size: 1.6em;
          margin-bottom: 1em;
        }
        .link_heading {
          font-size: 2em;
          margin-bottom: 1em;
        }
        .link_list {
          .link {
            li {
              font-size: 1.6em;
              margin-bottom: 0.75em;
            }
          }
        }
        .social_wrapper {
          .social_box {
            margin: 0 1.7em;
            .social_icon {
              max-width: 1.8em;
            }
          }
        }
      }
    }

    .copyright_flex {
      flex-wrap: wrap;
      justify-content: flex-start;
      padding: 4em 0 0;
      .copyright {
        @include textStyles(1.4em, 1.714em, 0);
        margin-bottom: 1.429em;
      }
    }
  }
}
