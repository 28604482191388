@import "../../common.scss";

.store_row {
  margin: 0 auto;
  .store_col {
    padding: 0;
    margin-top: 2.125em;
    &:nth-child(3n + 2) .store_box {
      margin: 0 auto;
    }
    &:nth-child(3n + 3) .store_box {
      margin-left: auto;
    }
  }
  .store_box {
    position: relative;
    width: 100%;
    max-width: 95.745%;
    overflow: hidden;
    // transform: scale(1);
    // transition: all 0.35s ease-in;
    // cursor: pointer;
    // &:hover {
    //   .store_img {
    //     transform: scale(1.5);
    //     transition: all 0.35s ease-in;
    //   }
    // }
    .store_img {
      width: 100%;
      max-width: 100%;
      // transition: all 0.35s ease-in;
    }
    .details_box {
      background-color: #050709b3;
      width: 100%;
      max-width: 100%;
      position: absolute;
      bottom: 0;
      left: 0;
      padding: 1.25em 1em;
    }
    .store_title {
      color: #ffffff;
      font-size: 1.75em;
      font-weight: 400;
      font-family: $alike;
      letter-spacing: 0px;
      line-height: normal;
      margin-bottom: 0.5em;
      text-align: center;
      text-transform: uppercase;
    }
    .store_details {
      color: #ffffff;
      font-size: 1.125em;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: normal;
      text-align: center;
    }
  }
}

@media (max-width: 1536px) {
  .store_row {
    .store_box {
      .store_details {
        font-size: 1.455em;
      }
    }
  }
}

@media (max-width: 992px) {
  .store_row {
    .store_box {
      &:hover {
        .store_img {
          transform: scale(1);
        }
      }
      .store_title {
        font-size: 1.6em;
      }
      .store_details {
        font-size: 1.4em;
      }
    }
  }
}

@media (max-width: 991px) {
  .store_row {
    .store_col {
      &:nth-child(3n + 2) .store_box {
        margin: 0;
      }
      &:nth-child(3n + 3) .store_box {
        margin-left: 0;
      }
      &:nth-child(2n + 1) {
        padding-right: 1em;
      }
      &:nth-child(2n + 2) {
        padding-left: 1em;
      }
    }
    .store_box {
      max-width: 100%;
    }
  }
}

@media (max-width: 767px) {
  .store_row {
    .store_col {
      margin-top: 2em;
      &:nth-child(2n + 1) {
        padding-right: 0;
      }
      &:nth-child(2n + 2) {
        padding-left: 0;
      }
    }
    .store_box {
      &:hover {
        .store_img {
          transform: scale(1);
        }
      }
      .details_box {
        padding: 2em 1em;
      }
      .store_title {
        font-size: 2em;
        letter-spacing: 0px;
        line-height: normal;
        margin-bottom: 0.5em;
      }
      .store_details {
        font-size: 1.4em;
        letter-spacing: 0px;
        line-height: normal;
      }
    }
  }
}
