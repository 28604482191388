@import "./common-mixins.scss";

$color-ffffff: #ffffff;
$color-050709: #050709;
$color-A32D3D: #a32d3d;
$color-393939: #393939;
$color-F4F4F4: #f4f4f4;
$color-00000029: #00000029;
$container-2560: 2226px;
$container-1920: 1632px;
$container-1700: 1619px;
$container-1620: 1480px;
$container-1536: 1356px;
$container-1440: 1200px;
$container-1366: 1194px;
$container-1280: 1113px;
$container-1194: 1031px;
$container-1080: 931px;
$container-992: 750px;
$alike: "Alike", serif;

body {
  font-family: "Cera Round Pro DEMO", sans-serif;
  padding: 8.563em 0 0;
  color: $color-050709;
}

img {
  object-fit: cover;
  height: auto;
}

::-webkit-scrollbar {
  width: 8px;
  background-color: transparent;
}

::-webkit-scrollbar-thumb {
  background-color: $color-A32D3D;
}

*,
*::before,
*::after {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

p,
ul,
h1,
h2,
h3,
h4,
h5,
h6,
figure {
  margin: 0;
  padding: 0;
}

html {
  scroll-behavior: smooth;
}

a {
  text-decoration: none;
}

:focus {
  outline: none;
  outline: -webkit-focus-ring-color auto 0px;
}

::-moz-focus-inner {
  border: 0;
}

button:focus {
  outline: 0px dotted;
  outline: 0px auto -webkit-focus-ring-color;
}

li {
  list-style-type: none;
}

[type="submit"],
button,
html [type="button"] {
  appearance: none;
  -webkit-appearance: none;
}

input [type="checkbox"] {
  appearance: none;
  -webkit-appearance: none;
}

input:hover,
input:focus,
input:active {
  background-color: $color-F4F4F4 !important;
  border: 1px solid transparent;
  box-shadow: 0px 3px 6px #00000014;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active,
textarea:-webkit-autofill,
textarea:-webkit-autofill:hover,
textarea:-webkit-autofill:focus,
textarea:-webkit-autofill:active,
select:-webkit-autofill,
select:-webkit-autofill:hover,
select:-webkit-autofill:focus,
select:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s;
  -webkit-text-fill-color: $color-050709 !important;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type="number"] {
  appearance: textfield;
  -moz-appearance: textfield;
}

.form_input {
  @include inputtextStyle;
  &::-webkit-input-placeholder {
    /* Edge */
    color: $color-050709;
    background-color: $color-F4F4F4;
    box-shadow: 0px 3px 6px #00000014;
  }
  &:-ms-input-placeholder {
    /* Internet Explorer 10-11 */
    color: $color-050709;
    background-color: $color-F4F4F4;
    box-shadow: 0px 3px 6px #00000014;
  }
  &::placeholder {
    color: $color-050709;
    background-color: $color-F4F4F4;
    box-shadow: 0px 3px 6px #00000014;
  }
}

.form_input:-webkit-autofill,
.form_input:-webkit-autofill:hover,
.form_input:-webkit-autofill:focus,
.form_input:-webkit-autofill:active {
  transition: background-color 5000s ease-in-out 0s, color 5000s ease-in-out 0s;
}

.swiper-wrapper {
  -webkit-transition-timing-function: linear !important;
  -o-transition-timing-function: linear !important;
  transition-timing-function: linear !important;
}

.form_field_wrapper {
  margin-bottom: 2.5em;
  .field_title {
    @include textStyles(1.375em, 1.2em, 0);
    color: rgba($color: $color-393939, $alpha: 0.6);
    padding: 0 0 0.545em;
  }
}

.common_cta {
  @include ctaStyle;
  &::before {
    content: "";
    background-color: $color-A32D3D;
    width: 0;
    height: 100%;
    position: absolute;
    left: 0;
    z-index: -1;
    transition: all 0.35s;
  }
  &:hover {
    color: $color-ffffff;
    &::before {
      width: 100%;
      transition: all 0.35s;
    }
  }
}

table {
  -webkit-user-select: none;
  -moz-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.error_message {
  display: block;
  font-size: 1.125em;
  margin-top: 0.5em;
  color: red;
  &:first-letter {
    text-transform: uppercase;
  }
}

.section_title {
  @include sectionTitle;
}

.my_container {
  width: 100%;
  margin: 0 auto;
  padding: 0 15px;
}

@media (min-width: 2561px) {
  body {
    font-size: 18px;
  }
  .my_container {
    max-width: $container-2560;
  }
}

@media (max-width: 2560px) {
  body {
    font-size: 18px;
  }
  .my_container {
    max-width: $container-2560;
  }
}

@media (max-width: 1920px) {
  body {
    font-size: 16px;
  }
  .my_container {
    max-width: $container-1920;
  }
}

@media (max-width: 1700px) {
  body {
    font-size: 13px;
  }
  .my_container {
    max-width: $container-1700;
  }
}

@media (max-width: 1620px) {
  body {
    font-size: 13px;
  }
  .my_container {
    max-width: $container-1620;
  }
}

@media (max-width: 1536px) {
  body {
    font-size: 11px;
  }
  .my_container {
    max-width: $container-1536;
  }
}

@media (max-width: 1440px) {
  .my_container {
    max-width: $container-1440;
  }
}

@media (max-width: 1366px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: $container-1366;
  }
}

@media (max-width: 1280px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: $container-1280;
  }
}

@media (max-width: 1194px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: $container-1194;
  }
}

@media (max-width: 1080px) {
  body {
    font-size: 10px;
    padding: 7.6em 0 0;
  }
  .my_container {
    max-width: $container-1080;
  }
}

@media (max-width: 992px) {
  body {
    font-size: 10px;
  }
  .my_container {
    max-width: $container-992;
  }

  .section_title {
    @include textStyles(2.8em, 1.286em, 0);
  }
}

@media (max-width: 600px) {
  .my_container {
    max-width: 100%;
    padding: 0 16px;
  }
  .form_input {
    font-size: 1.6em;
    line-height: normal;
    padding: 1.125em 1.25em;
  }
  .common_cta {
    font-size: 1.4em;
    height: 3.5em;
    &:hover {
      color: $color-A32D3D;
      &::before {
        display: none;
      }
    }
  }

  .form_field_wrapper {
    margin: 0 0 2.4em;
    .field_title {
      @include textStyles(1.6em, 1.25em, 0);
      padding: 0 0 0.75em;
    }
  }

  .sticky_booknow {
    width: 100%;
    position: fixed;
    bottom: 0;
    z-index: 10;
    .common_cta {
      font-size: 1.8em;
      background: transparent linear-gradient(90deg, #b20710 0%, #ee2c3c 100%)
        0% 0% no-repeat padding-box;
      margin: 0;
      padding: 0;
      height: auto;
      text-align: center;
      border-radius: 0;
      height: 100%;
      min-height: 7.5vh;
      max-height: 7.5vh;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
}
