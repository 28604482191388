@mixin textStyles($fontSize, $lineHeight, $letterSpacing) {
  font-size: $fontSize;
  line-height: $lineHeight;
  letter-spacing: $letterSpacing;
}

@mixin headingStyle {
  font-size: 1.875em;
  font-family: "Cera Round Pro Regular", sans-serif;
  font-weight: 800;
  margin-bottom: 0.8em;
}

@mixin sectionTitle {
  @include textStyles(2.25em, 1.25em, 0);
  text-transform: uppercase;
  color: $color-A32D3D;
  font-family: $alike;
}

@mixin ctaStyle {
  position: relative;
  font-family: "Cera Round Pro Regular", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  width: 100%;
  font-size: 1.375em;
  line-height: 1.864em;
  height: 2.909em;
  background-color: $color-ffffff;
  border: 1px solid $color-A32D3D;
  border-radius: 0;
  color: $color-A32D3D;
  text-align: center;
  overflow: hidden;
  z-index: 2;
  cursor: pointer;
  transition: all 0.3s;
  position: relative;
  transition: 0.5s ease;
}

@mixin arrowStyle {
  position: absolute;
  right: 5em;
  width: 100%;
  max-width: 2em;
  top: 0;
  bottom: 0;
  margin: auto;
  transition: 0.3s;
}

@mixin hoverStyle {
  box-shadow: 0px 10px 23px rgba($color: #050709, $alpha: 0.16);
}

@mixin descStyle {
  font-size: 1.5em;
  color: $color-050709;
}

@mixin inputStyle {
}

@mixin inputtextStyle {
  appearance: none;
  -webkit-appearance: none;
  outline: none;
  display: block;
  width: 100%;
  font-size: 1.375em;
  line-height: 1.773em;
  padding: 0.955em 1.455em;
  background-color: transparent !important;
  border: 0;
  border: 1px solid #3939394d;
  color: $color-393939;
  font-weight: 600;
}

@mixin inputRadioStyle {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;

  border-radius: 50%;
  width: 100%;
  min-width: 2em;
  max-width: 2em;
  height: 100%;
  min-height: 2em;
  max-height: 2em;

  border: 0.5em solid $color-ffffff;
  transition: 0.2s all linear;
  margin-right: 5px;
  background-color: $color-ffffff;

  position: relative;
  top: 0.075em;
  cursor: pointer;
}

@mixin stripStyle {
  color: $color-ffffff;
  background-color: $color-050709;
  text-align: center;
  padding: 3.875em 0;
  font-family: "Cera Round Pro Regular", sans-serif;
  font-weight: bolder;
}
