@import "../../../common.scss";

.hero_banner {
  position: relative;
  .banner_swiper {
    .banner_slide {
      .banner_img {
        width: 100%;
        height: 100%;
        max-height: 43.125em;
      }
    }
    .navigation_wrapper {
      width: 100%;
      height: fit-content;
      pointer-events: none;
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      margin: auto;
      display: flex;
      justify-content: space-between;
      z-index: 1;
      .nav_icon {
        pointer-events: all;
        width: 100%;
        max-width: 6.188em;
        object-fit: contain;
        cursor: pointer;
      }
    }
  }
}

@media (max-width: 992px) {
  .hero_banner {
    .banner_swiper {
      .banner_slide {
        .banner_img {
          min-height: 40vh;
          max-height: 40vh;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .hero_banner {
    .banner_swiper {
      .banner_slide {
        .banner_img {
          min-height: 90vh;
          max-height: 90vh;
        }
      }
      .navigation_wrapper {
        .nav_icon {
          max-width: 4.8em;
        }
      }
    }
  }
}
