@import "../../common.scss";

.storeReq_Acc {
  padding: 3.75em 0 3.75em;
  .accordion-wrapper {
    padding: 0;
    transition: all 0.35s ease-in;
    margin: 0 0 3.75em;
    &:last-child {
      margin-bottom: 0;
    }
  }

  .accordion-item {
    overflow: hidden;
    max-height: 0;
    transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
  }

  .accordion-item.active {
    transition: max-height 0.3s cubic-bezier(1, 0, 1, 0);
    height: auto;
    max-height: 9999px;
  }

  .accordion-title {
    @include textStyles(2.25em, 1.25, 0px);
    background-color: #f4f4f4;
    border: 1px solid rgba(57, 57, 57, 0.4);
    color: #a32d3d;
    font-weight: 400;
    font-family: $alike;
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.7778em 1.1112em;
    transition: all 0.35s ease-in;
    cursor: pointer;
    &.active {
      background-color: #a32d3d;
      color: #ffffff;
      transition: all 0.35s ease-in;
    }
    .plus-minus {
      color: #ffffff;
    }
    .down_arrow {
      width: 100%;
      max-width: 0.66em;
      &.acc_open {
        transform: rotate(180deg);
      }
    }
  }

  .storereq_table {
    // border: 1px solid rgba(57, 57, 57, 0.4);
    width: 100%;
    td {
      border: 1px solid rgba(57, 57, 57, 0.4);
    }
    .table_ques,
    .table_answ {
      color: #050709;
      font-size: 1.375em;
      font-weight: 400;
      letter-spacing: 0px;
      line-height: 0.9091;
      padding: 1.09091em 1.8182em;
    }

    .table_ques {
      width: 77.5%;
    }

    .table_answ {
      font-weight: bold;
      padding: 1.09091em 1.8182em 1.09091em 3.591em;
      width: 22.5%;
    }
  }
}

@media (max-width: 1536px) {
  .storeReq_Acc {
    .storereq_table {
      .table_ques,
      .table_answ {
        font-size: 1.455em;
      }
    }
  }
}

@media (max-width: 992px) {
  .storeReq_Acc {
    .accordion-title {
      font-size: 2.4em;
      letter-spacing: 0px;
      line-height: 1.3572;
      padding: 0.7143em 0.5715em;
    }
    .storereq_table {
      .table_ques,
      .table_answ {
        font-size: 1.6em;
        letter-spacing: 0px;
        line-height: 1.625;
        padding: 1.25em 1em;
      }
      .table_ques {
        width: 60%;
      }
      .table_answ {
        width: 40%;
        text-align: center;
      }
    }
  }
}

@media (max-width: 600px) {
  .storeReq_Acc {
    padding: 3em 0 3em;
    .accordion-wrapper {
      margin-bottom: 2em;
    }

    .accordion-title {
      font-size: 2.4em;
      letter-spacing: 0px;
      line-height: 1.3572;
      padding: 0.7143em 0.5715em;
      align-items: flex-start;
    }
    .down_arrow {
      margin-top: 0.25em;
    }
    .storereq_table {
      .table_ques,
      .table_answ {
        font-size: 1.6em;
        letter-spacing: 0px;
        line-height: 1.625;
        padding: 1.25em 1em;
      }
      .table_ques {
        width: 60%;
      }
      .table_answ {
        width: 40%;
        text-align: center;
      }
    }
  }
}
