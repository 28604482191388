@import "../../../common.scss";

.new_fabrics {
  padding: 3.75em 0 3.75em;
  .fab_title {
    text-align: center;
  }
  .fab_accordion_box {
    padding: 5.688em 0 0;
    .container1 {
      padding: 0 4em 0 0;
      .accordion_box {
        &:first-child {
          .accordion-title {
            padding-top: 0;
            border: none;
          }
        }

        .accordion-title {
          @include textStyles(2em, 0.938em, 0);
          color: $color-A32D3D;
          font-weight: 500;
          border: none;
          display: flex;
          justify-content: space-between;
          align-items: center;
          text-transform: uppercase;
          cursor: pointer;
          padding: 1.25em 0 0.875em;
          margin: 0 0 0 20px;
          border-top: 1px solid rgba($color: $color-393939, $alpha: 0.3);
          .plus-minus {
            width: 100%;
            max-width: 0.75em;
            object-fit: contain;
            transition: transform 0.3s;
            &.active {
              transform: rotate(180deg);
            }
          }
        }

        .accordion-item {
          overflow: hidden;
          max-height: 0;
          //   transition: max-height 0.35s cubic-bezier(0, 1, 0, 1);
        }

        .accordion-item.active {
          //   transition: max-height 0.35s cubic-bezier(1, 0, 1, 0);
          height: auto;
          max-height: 9999px;
        }

        .accordion-content {
          @include textStyles(1.375em, 1.455em, 0);
          color: $color-050709;
          font-weight: 600;
          padding: 0 0 1.818em;
          margin: 0 0 0 20px;
          .large_title {
            font-size: 1.273em;
          }
          ul {
            padding: 0 0 0 1em;
            margin: 0 0 0 -20px;
            li {
              list-style-type: disc;
              padding: 0 0 1em;
              &:last-child {
                padding-bottom: 0;
              }
              &::marker {
                color: $color-A32D3D;
                font-size: 0.75em;
              }
            }
          }
        }
      }
    }
    .container2 {
      padding: 0 0 0 4em;
      .fab_img {
        width: 100%;
        height: 100%;
        max-width: 100%;
        max-height: 32.438em;
      }
    }
  }
}

@media (max-width: 1536px) {
  .new_fabrics {
    .fab_accordion_box {
      .container1 {
        .accordion_box {
          .accordion-content {
            font-size: 1.455em;
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .new_fabrics {
    padding: 6em 0 0.6em;
    .fab_title {
      text-align: left;
    }
    .fab_accordion_box {
      padding: 2.4em 1.5em 0;
      .container1 {
        padding: 0;
        .accordion_box {
          .accordion-item {
            .fab_img {
              width: 100%;
              height: 100%;
              max-width: 100%;
              max-height: 100%;
              padding: 0 0 2.4em;
            }
          }
          .accordion-title {
            @include textStyles(2.6em, 1.154em, 0);
            padding: 0.923em 0;
            margin: 0;
          }
          .accordion-content {
            padding: 0 0 1.5em;
            @include textStyles(1.6em, 1.5em, 0);
            margin: 0 0 0 20px;
            ul {
              li::marker {
                font-size: 1em;
              }
            }
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .new_fabrics {
    .fab_accordion_box {
      .container1 {
        .accordion_box {
          .accordion-title {
            font-size: 2.2em;
            align-items: flex-start;
            .plus-minus {
              margin-top: 0.25em;
            }
          }
        }
      }
    }
  }
}
