@import "../../common.scss";

.thank_sec1 {
  background-color: #F4F4F4;
  .thank_row {
    margin: 0 auto;
  }
  .thank_col {
    padding: 0;
    align-self: center;
    height: 100%;
    .content_wrapper {
      padding: 1em 5.5625em 1em 5.6875em;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;
    }
    .thank_heading {
      color: #a32d3d;
      font-size: 7.5em;
      font-weight: 400;
      font-family: $alike;
      letter-spacing: 0px;
      line-height: 1.25;
      margin-bottom: 0.3334em;
    }
    .thank_desc {
      color: #050709;
      font-size: 2.5em;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: normal;
      padding-bottom: 0.5em;
    }
    .cta_wrapper {
      width: 100%;
      max-width: 16.5em;
      margin-top: 2.5em;
    }
    .img_wrapper {
      height: 100%;
    }
    .thank_img {
      width: 100%;
      max-width: 100%;
      height: 100%;
    }
  }
}

@media (max-width: 1194px) {
  .thank_sec1 {
    .thank_col {
      .content_wrapper {
        padding: 1em 4.5625em 1em 4.6875em;
      }
      .thank_heading {
        font-size: 6em;
      }
    }
  }
}

@media (max-width: 1024px) and (min-height: 801px) {
  .thank_sec1 {
    .thank_row {
      height: 74vh;
    }
  }
}

@media (max-width: 991px) {
  .thank_sec1 {
    .thank_col {
      .content_wrapper {
        padding: 5em 4.5625em 5em 4.6875em;
      }
    }
  }
}

@media (max-width: 991px) and (min-height: 801px) {
  .thank_sec1 {
    .thank_row {
      height: 100%;
    }
  }
}

@media (max-width: 600px) {
  .thank_sec1 {
    .thank_col {
      .content_wrapper {
        padding: 3.2em 2.5em 4em 2.5em;
        text-align: center;
      }
      .thank_heading {
        font-size: 4.4em;
        letter-spacing: 0px;
        line-height: 1.25;
        margin-bottom: 0.1819em;
      }
      .thank_desc {
        font-size: 2em;
        letter-spacing: 0px;
        line-height: 1.5;
        padding-bottom: 1em;
      }
      .thank_desc_big {
        font-size: 2.4em;
        letter-spacing: 0px;
        line-height: 1.75;
        padding-bottom: 0.8334em;
      }
      .cta_wrapper {
        margin: 2em auto 0;
      }
    }
  }
}