@import "../../../common.scss";

.hero_ticker {
  position: relative;
  width: 100%;
  max-width: 84.5em;
  margin: -4em auto 3.75em;
  padding: 2.813em 0 3em;
  background: $color-A32D3D;
  color: $color-ffffff;
  z-index: 1;
  box-shadow: 0px 3px 6px $color-00000029;
  .ticker_flex {
    display: flex;
    justify-content: space-between;
    .ticker_box {
      width: 100%;
      padding: 0 3.75em;
      .text_container {
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        .title {
          width: 100%;
          display: block;
          @include textStyles(1.375em, 1.273em, 0);
          padding: 0 0 0.545em;
          text-transform: uppercase;
        }
        .unit {
          width: 100%;
          display: block;
          @include textStyles(1.5em, 1.25em, 0);
          padding: 0.417em 0 0;
          text-transform: uppercase;
        }
      }
      &.ticker_box1 {
        max-width: 25%;
        border-right: 1px solid rgba($color: $color-ffffff, $alpha: 0.4);
      }
      &.ticker_box2 {
        max-width: 40%;
        border-right: 1px solid rgba($color: $color-ffffff, $alpha: 0.4);
      }
      &.ticker_box3 {
        max-width: 35%;
      }
    }
  }
}

@media (max-width: 992px) {
  .hero_ticker {
    padding: 0 1.6em;
    .ticker_flex {
      flex-wrap: wrap;
      .ticker_box {
        padding: 3.2em 0;
        border: none;
        &.ticker_box1,
        &.ticker_box2,
        &.ticker_box3 {
          max-width: 100%;
          border: none;
        }
        &.ticker_box2,
        &.ticker_box3 {
          border-top: 1px solid rgba($color: $color-ffffff, $alpha: 0.4);
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .hero_ticker_wrapper {
    padding: 0 1.4em;
  }
  .hero_ticker {
    margin: -4em auto 3em;
    .ticker_flex {
      .ticker_box {
        padding: 2.4em 0;
        .text_container {
          .title {
            @include textStyles(1.4em, 1.286em, 0);
            padding: 0 0 0.571em;
          }
          .unit {
            @include textStyles(1.6em, 1.25em, 0);
            padding: 0.5em 0 0;
          }
        }
      }
    }
  }
}
