@import "../../common.scss";

.header_sec {
  width: 100%;
  height: 100%;
  min-height: 8.563em;
  max-height: 8.563em;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9;
  padding: 1.438em 0;
  background-color: $color-ffffff;
  -webkit-box-shadow: 0px 3px 6px $color-00000029;
  -moz-box-shadow: 0px 3px 6px $color-00000029;
  box-shadow: 0px 3px 6px $color-00000029;
  .logo_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 6em;
    .logo_img {
      position: relative;
      width: 100%;
      height: 100%;
      object-fit: contain;
      max-width: 13.938em;
      max-height: 5.688em;
    }
  }
}

@media (max-width: 1080px) {
  .header_sec {
    min-height: 7.6em;
    max-height: 7.6em;
    z-index: 201;
    padding: 1.2em 0;
    .logo_wrapper {
      z-index: 9;
      height: 100%;
      height: 5em;
      .logo_img {
        left: 0;
        max-width: 12.7em;
      }
    }
  }
}
