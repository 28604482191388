@import "../../common.scss";

.arvind_stores {
  padding-top: 7.5em;
  .desc {
    color: #050709;
    font-size: 2em;
    font-weight: 400;
    letter-spacing: 0px;
    line-height: 1.3125;
    margin-bottom: 0.8125em;
  }
  .content_wrapper {
    padding: 7.5em 0 3.75em;
    .heading {
      color: #a32d3d;
      font-size: 2.25em;
      font-weight: 400;
      font-family: $alike;
      letter-spacing: 0px;
      line-height: 0.8334;
      margin-bottom: 0.778em;
    }
    .description {
      color: #393939;
      font-size: 1.5em;
      font-weight: 500;
      letter-spacing: 0px;
      line-height: 1.25;
      margin-bottom: 1.667em;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .semi_bold {
      font-weight: 600;
    }
    .fabric_details_wrapper {
      li {
        color: #050709;
        font-size: 1.375em;
        font-weight: 400;
        letter-spacing: 0px;
        line-height: 1.4545;
        list-style: disc;
        margin-bottom: 1.8182em;
        &::marker {
          color: #a32d3d;
        }
      }
    }
  }

  .store_row {
    .store_box {
      transition: all 0.35s ease-in;
      margin-bottom: 0;
      // cursor: pointer;
      position: relative;
      top: 0;
      &:hover {
        transition: all 0.35s ease-in;
        top: -2.5em;
        .store_img {
          transition: all 0.35s ease-in;
        }
      }
      .store_img {
        transition: all 0.35s ease-in;
      }
    }
  }

  .pink {
    color: #a32d3d;
  }
  .medium {
    font-weight: 500;
  }
  .bold {
    font-weight: 700;
  }
}

@media (max-width: 1536px) {
  .arvind_stores {
    .content_wrapper {
      .fabric_details_wrapper {
        li {
          font-size: 1.455em;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .arvind_stores {
    .desc {
      font-size: 2em;
    }
    .content_wrapper {
      .heading {
        font-size: 2.8em;
      }
      .description {
        font-size: 1.8em;
      }
      .fabric_details_wrapper {
        padding-left: 1.8em;
        li {
          font-size: 1.6em;
        }
      }
    }

    .store_row {
      .store_box {
        &:hover {
          top: 0;
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .arvind_stores {
    padding-top: 6em;
    .desc {
      font-size: 2em;
      letter-spacing: 0px;
      line-height: 1.5;
      margin-bottom: 0.2em;
      text-align: center;
    }
    .content_wrapper {
      padding: 6em 0 3em;
      .heading {
        font-size: 2.8em;
        letter-spacing: 0px;
        line-height: 1.3572;
        margin-bottom: 0.8572em;
      }
      .description {
        font-size: 2em;
        letter-spacing: 0px;
        line-height: 1.5;
        margin-bottom: 1.2em;
      }
      .fabric_details_wrapper {
        padding-left: 1.8em;
        li {
          font-size: 1.6em;
          letter-spacing: 0px;
          line-height: 1.625;
          margin-bottom: 1.5em;
        }
      }
    }
  }
}
