@import "../../common.scss";

.notfound_sec1 {
  padding: 7.5em 0;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .title {
    color: #a32d3d;
    font-size: 20em;
    font-weight: 500;
    font-family: $alike;
    letter-spacing: 0px;
    line-height: 0.8;
    margin-bottom: 0.2em;
    .black {
      color: #393939cc;
    }
  }
  .subtitle {
    color: #393939;
    font-size: 4em;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: normal;
    padding: 0.5em 0;
  }
  .desc {
    color: #393939cc;
    font-size: 2.5em;
    font-weight: 600;
    letter-spacing: 0px;
    line-height: normal;
  }
  .cta_wrapper {
    width: 100%;
    max-width: fit-content;
    margin: 4em auto 0;
    .common_cta {
      padding-left: 1em;
      padding-right: 1em;
    }
  }
}

@media (min-width: 2560px) and (min-height: 1580px) {
  .notfound_sec1 {
    height: 70vh;
  }
}

@media (max-width: 1024px) and (min-height: 901px) {
  .notfound_sec1 {
    height: 75vh;
  }
}

@media (max-width: 600px) {
  .notfound_sec1 {
    padding: 6em 0;
    .title {
        font-size: 14em;
    }
    .subtitle {
        font-size: 3em;
    }
    .desc {
        font-size: 2em;
    }
    .cta_wrapper {
        margin: 4em auto 0;
    }
  }
}
