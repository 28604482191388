@import "../../common.scss";

.expansion_plan_sec {
  padding: 3.75em 0 3.75em;
  .heading {
    // nothing
    color: #a32d3d;
    font-size: 2.25em;
    font-weight: 400;
    font-family: $alike;
    letter-spacing: 0px;
    line-height: 0.8334;
    margin-bottom: 0.7223em;
    text-align: center;
  }
  .store_row {
    .store_box {
      // transform: scale(1);
      transition: all 0.35s ease-in;
      // cursor: pointer;
      &:hover {
        transition: all 0.35s ease-in;
        .store_img {
          transform: scale(1.1);
          transition: all 0.35s ease-in;
        }
      }
      .store_img {
        transform: scale(1);
        transition: all 0.35s ease-in;
      }
    }
  }
}

@media (max-width: 992px) {
  .expansion_plan_sec {
    .heading {
      font-size: 2.8em;
    }
  }
}

@media (max-width: 600px) {
  .expansion_plan_sec {
    padding: 3em 0 3em;
    .heading {
      font-size: 2.8em;
      letter-spacing: 0px;
      line-height: 1.3572;
      margin-bottom: 0.14286em;
    }
  }
}
