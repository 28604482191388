@import "../../../common.scss";

.common_actual_count {
  @include textStyles(5.5em, 1.261em, 0);
  color: $color-ffffff;
  font-family: $alike;
}
@media (max-width: 992px) {
  .common_actual_count {
    @include textStyles(4em, 1.258em, 0);
  }
}
@media (max-width: 500px) {
  .common_actual_count {
    @include textStyles(4em, 1.258em, 0);
  }
}
