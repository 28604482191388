@import "../../../common.scss";

.the_brand {
  padding: 3.75em 0 7.5em;
  .brand_box1 {
    padding: 0 3.75em 0 0;
    .the_brand_img {
      width: 100%;
      height: 100%;
      padding: 0 0 2.4em;
    }
    .container {
      &.container1 {
        padding: 0 0 1.875em;
      }
      &.container2 {
        padding: 1.875em 0 0;
      }
      .brand_subtitle {
        @include textStyles(3.375em, 1.185em, 0);
        padding: 0.37em 0 0.574em;
        font-weight: bold;
      }
      .brand_desc {
        @include textStyles(1.375em, 1.455em, 0);
        color: rgba($color: $color-393939, $alpha: 0.8);
        font-weight: 500;
      }
      .brand_list {
        @include textStyles(1.375em, 1.273em, 0);
        padding: 1.273em 0 0;
        font-weight: 600;
        .list_item {
          list-style-type: disc;
          padding: 0 0 1em;
          &::marker {
            font-size: 1em;
            color: $color-A32D3D;
          }
        }
      }
    }
  }
  .brand_box2 {
    // padding: 0 0 0 3.75em;
    overflow: hidden;
    width: 100%;
    max-width: 92.52%;
    transition: all 0.35s ease-in;
    margin-left: auto;
    &:hover {
      transition: all 0.35s ease-in;
      .the_brand_img {
        margin-left: 0;
        transform: scale(1.1);
        transition: all 0.35s ease-in;
      }
    }
    .the_brand_img {
      width: 100%;
      height: 100%;
      transform: scale(1);
      transition: all 0.35s ease-in;
    }
  }
}

@media (max-width: 1536px) {
  .the_brand {
    .brand_box1 {
      .container {
        .brand_desc {
          font-size: 1.455em;
        }
        .brand_list {
          .list_item {
            font-size: 1.0579em;
          }
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .the_brand {
    padding: 3em 0 6em;
    .brand_box1 {
      padding: 0 1.5em 0 0;
      .container {
        .brand_subtitle {
          @include textStyles(3.2em, 1.313em, 0);
          padding: 0.75em 0;
        }

        .brand_desc,
        .brand_list {
          @include textStyles(1.6em, 1.625em, 0);
        }

        .brand_list {
          padding: 1.5em 0 0 16px;
        }

        &.container1 {
          padding: 0 0 2em;
        }

        &.container2 {
          padding: 2em 0 0;
        }
      }
    }
    .brand_box2 {
      // padding: 0 0 0 1.5em;
      padding: 0;
      &:hover {
        .the_brand_img {
          transform: scale(1);
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .the_brand {
    .brand_box1,
    .brand_box2 {
      padding: 0;
      &:hover {
        .the_brand_img {
          transform: scale(1);
        }
      }
    }
  }
}
