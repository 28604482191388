@import "../../../common.scss";
.home_milestone {
  background: rgba($color: $color-393939, $alpha: 0.05);
  .content_box {
    padding: 7.5em 0;
    .mil_title {
      text-align: center;
    }

    .mil_swiper {
      padding: 2.813em 0 0;

      .mil_box {
        text-align: center;
        padding: 1.25em 2.35em 0;
        border-top: 1px solid rgba($color: $color-393939, $alpha: 0.3);
        // cursor: move;
        &::before {
          content: "";
          position: absolute;
          top: -0.5em;
          left: 0;
          right: 0;
          margin: 0 auto;
          width: 1em;
          height: 1em;
          border-radius: 50%;
          background-color: $color-A32D3D;
        }
        .mil_year {
          @include textStyles(1.25em, 1.6em, 0);
          color: rgba($color: $color-050709, $alpha: 0.8);
          padding: 0 0 1em;
        }
        .mil_desc {
          @include textStyles(1.5em, 1.333em, 0);
          color: rgba($color: $color-050709, $alpha: 0.8);
          font-weight: bold;
        }
      }
      .navigation_wrapper {
        width: 100%;
        height: fit-content;
        pointer-events: none;
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        margin: 0 auto;
        display: flex;
        justify-content: space-between;
        z-index: 1;
        padding: 1.4em 0 0;
        .nav_icon {
          pointer-events: all;
          width: 100%;
          max-width: 2.813em;
          object-fit: contain;
          cursor: pointer;
        }
      }
    }
  }
}

@media (max-width: 992px) {
  .home_milestone {
    .content_box {
      padding: 4em 0;
      .mil_swiper {
        .mil_box {
          padding: 1.2em 4em 0;
          .mil_year {
            @include textStyles(1.6em, 2em, 0);
            padding: 0 0 1em;
          }
          .mil_desc {
            @include textStyles(1.8em, 1.444em, 0);
          }
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .home_milestone {
    .content_box {
      padding: 4em 0;
      .mil_swiper {
        .mil_box {
          padding: 1.2em 2em 0;
        }
      }
    }
  }
}
