@import "../../../common.scss";

.home_applyform {
  padding: 3.75em 0 7.5em;
  .apply_title {
    text-align: center;
  }
  .form_container {
    padding: 3.75em 0 0;
    .form_row {
      justify-content: space-between;
      .form_column {
        max-width: calc(50% - 2.5em);
      }
    }

    .cta_wrapper {
      width: 100%;
      max-width: 141px;
      margin: 0 auto;
    }

    .dropdown_wrapper {
      position: relative;

      .select_dropdown {
        background: $color-ffffff;
        width: 100%;
        display: block;
        position: absolute;
        max-height: 25em;
        overflow: auto;
        z-index: 5;
        border: 1px solid #ccc;
        .dropdown_option {
          @include inputtextStyle;
          border: none;
        }
      }
      .drop_arrow {
        width: 100%;
        max-width: 1em;
        position: absolute;
        top: 0;
        bottom: 0;
        margin: auto 0;
        right: 1.5em;
        z-index: 1;
        pointer-events: none;
        &.active {
          transform: rotate(-180deg);
        }
      }
    }

    .select-dropdown,
    .arvind-dropdown * {
      margin: 0;
      padding: 0;
      position: relative;
      box-sizing: border-box;
      cursor: pointer;
    }

    .arvind-dropdown {
      position: relative;
      background-color: transparent;
      border-radius: 4px;
    }

    .select-dropdown:after {
      display: none;
    }

    .arvind-dropdown select::-ms-expand {
      display: none;
    }

    .arvind-dropdown select {
      display: block;
      width: 100%;
      max-width: 100%;
      font-size: 1.375em;
      line-height: 1.773em;
      padding: 0.955em 1.455em;
      border: 1px solid #3939394d;
      color: $color-393939;
      font-weight: 600;
      outline: none;
      appearance: none;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      background-image: url("../../../source/images/home/select-drop-icon.svg");
      background-repeat: no-repeat;
      background-size: 1em;
      background-position: calc(100% - 2em);
      background-color: #fff;
      &:hover {
        box-shadow: 0px 3px 6px #00000014;
      }
    }

    .arvind-dropdown select:hover,
    .arvind-dropdown select:active,
    .arvind-dropdown select:focus {
      outline: none;
      box-shadow: none;
      border: 1px solid transparent;
      background-color: $color-F4F4F4 !important;
    }

    .arvind-dropdown select:hover {
      box-shadow: 0px 3px 6px #00000014;
    }

    .arvind-dropdown select * {
      font-weight: 600;
      outline: none;
      box-shadow: none;
    }
  }
}

@media (max-width: 992px) {
  .home_applyform {
    padding: 3em 0 6em;
    .form_container {
      .form_row {
        .form_column {
          max-width: calc(50% - 1.5em);
        }
      }
    }
  }
}

@media (max-width: 600px) {
  .home_applyform {
    .apply_title {
      text-align: left;
    }
    .form_container {
      padding: 2.4em 0 0;
      .form_row {
        .form_column {
          max-width: 100%;
        }
      }
      .dropdown_wrapper {
        .drop_arrow {
          max-width: 1.25em;
          opacity: 0.75;
        }
      }
    }
  }
}
