@import "../../common.scss";

.applynow_cta {
  position: fixed;
  right: 0;
  top: 15.4375em;
  z-index: 10;
  //   transform: rotate(360deg);
  -webkit-writing-mode: vertical-rl;
  writing-mode: vertical-rl;
  .applynow_text {
    background-color: #f5a02d;
    color: #ffffff;
    font-size: 1.375em;
    line-height: 1.8637;
    padding: 1.377em 0.432em;
    text-align: center;
    font-weight: 400;
    text-decoration: none;
    display: block;
    text-transform: uppercase;
  }
}

@media (max-width: 1536px) {
  .applynow_cta {
    .applynow_text {
      font-size: 1.455em;
    }
  }
}

@media (max-width: 992px) {
  .applynow_cta {
    .applynow_text {
      font-size: 1.6em;
    }
  }
}

@media (max-width: 600px) {
  .applynow_cta {
    // transform: rotate(0deg);
    writing-mode: initial;
    -webkit-writing-mode: initial;
    top: auto;
    right: initial;
    left: 0;
    bottom: 0;
    width: 100%;
    max-width: 100%;
    .applynow_text {
      font-size: 2em;
      font-weight: 400;
      line-height: normal;
      padding: 0.38em 0.5em;
    }
  }
}
